import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import { ReactComponent as CloseCrossIcon } from "../../../../assets/close-cross.svg";
import { Tabs } from "../../../../components/Tabs";
import { Table } from "../../../../components/Table";
import Pagination from "../../../../components/Pagination";
import { SortedComboBox, SORTING } from "../../../../components/SortedCombobox";
import {assessmentStore, loginStore, tablesStore} from "../../../../store";
import { INTROJS_SELECTOR } from "../../sections/home/components/student/StudentDashboardExcursion";
import { StatusLabel } from "../../sections/home/components/general/StatusLabel";
import { ASSESSMENT_STATUS } from "../../../../helpers/constants";
import { formatToUSDate } from "../../../../helpers/date.ts";
import {
  fetchCompletedAssessmentList,
  fetchPublishedAssessmentListByStudent,
  fetchUserAssessmentList, getTopicsForExtendedSearch
} from "../../../../actions/assessment";
import { StudentCompletedAssessments } from "../../sections/home/components/student/analytics";
import { Tooltip } from "../../../../components/Tooltip";
import {
  attachAssignInfoToPublishedAssessments,
  dividePracticeAndNotPracticeAssessments,
  isPaidAssessment,
  sortAssessmentsByNameAlphabetically,
  sortAssessmentsByNewest,
  sortAssessmentsByStatus,
} from "../../../../helpers/assessment";
import { filterItemsByPage } from "../../../../helpers/functions.ts";
import {SingleTypeSelect} from "../../../inviteUsers/components/SingleTypeSelect";
import {
  difficultyNameById,
  difficultyOptions,
  mathDomainOptions,
  readingAndWritingDomainOptions,
  SECTION
} from "../../../inviteUsers/components/ExtendedSearchSection";
import {MultipleCheckedInput} from "../../../inviteUsers/components/MultipleCheckedSelect";

const sectionOptions = [
  { id: 'Verbal', name: SECTION.READING_AND_WRITING, value: 'Verbal', },
  { id: SECTION.MATH, name: SECTION.MATH, value: SECTION.MATH, },
];

const backdropVariants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const modalVariants = {
  visible: { y: 0 },
  hidden: { y: 50 },
};

const TAB = {
  AVAILABLE: 'Assessivs',
  PRACTICE: 'Practice Assessivs',
  COMPLETED: 'Completed Assessivs'
};

const ASSESSMENTS_PER_PAGE = 7;

const tabs = [
  {
    id: TAB.PRACTICE,
    name: TAB.PRACTICE,
    value: TAB.PRACTICE,
  },
  {
    id: TAB.AVAILABLE,
    name: TAB.AVAILABLE,
    value: TAB.AVAILABLE,
  },
  {
    id: TAB.COMPLETED,
    name: TAB.COMPLETED,
    value: TAB.COMPLETED,
  },
];

const sectionTagId = 'eb3dd787-cb38-4347-abe7-3870759b8d16';
const domainTagId = 'b98294e3-5104-4e62-98a2-9d31b555a740';
const topicTagId = '81f6193a-ddb4-4eba-8e88-959a25ccabbe';
const difficultyTagId = 'f1886388-b39e-4944-98b8-9e4a8aaac791';

const isInternalTagVisible = (assessment) => {
  const { email } = loginStore.getState();
  const allowedEmails = [
    'pmcorp@prepmatters.com',
    'jason@scoreatthetop.com',
    'Barry@scoreatthetop.com',
    'marchantd@gmail.com',
    'mtaepakdee@gmail.com',
    'michelle@scoreatthetop.com',
    'brian@edisonprep.com',
    'silvia@edisonprep.com',
    'silvia@edisonprep.com',
    'robert@edisonprep.com',
    'alisha@edisonprep.com',
    'rowen@edisonprep.com',
  ];

  if (!allowedEmails.includes(email)) {
    return false;
  }

  const servicePlanTagId = '2ff7eed7-2540-48f6-ab2e-2ff297a88752';
  const servicePlanInternalValue = 'Internal';
  const assessmentServicePlanValue = assessment?.tags?.find((tag) => tag?.tagId === servicePlanTagId)?.values?.[0];

  return servicePlanInternalValue === assessmentServicePlanValue;
}

export const TryAssessmentModal = ({ closeModal }) => {
  const { publishedAssessments, assessments: assignedAssessments, setAssessments, setCompletedAssessments, setPublishedAssessments, setAssessmentId, setAssessmentVersionId, setEducatorId, resetResults, setResultsReady } = assessmentStore();
  const { studentsTableSortingType, availableAssessmentTablePage, assessmentTableTab, practiceAssessmentTablePage, setStudentsTableSortingType, setPracticeAssessmentTablePage, setAvailableAssessmentTablePage, setAssessmentTableTab,  } = tablesStore();
  const [currentTab, setCurrentTab] = useState(assessmentTableTab ?? TAB.PRACTICE);
  const [section, setSection] = useState('');
  const [domains, setDomains] = useState([]);
  const [topics, setTopics] = useState([]);
  const [difficulty, setDifficulty] = useState('');
  const [topicOptions, setTopicOptions] = useState([]);
  const navigate = useNavigate();

  const domainOptions = section === SECTION.MATH ? mathDomainOptions : readingAndWritingDomainOptions;

  const returnToTheFirstPage = () => {
    const handler = currentTab === TAB.AVAILABLE ? setAvailableAssessmentTablePage : setPracticeAssessmentTablePage;
    handler(1);
  }

  const handleOnClickTab = (tab) => {
    setCurrentTab(tab);
    setAssessmentTableTab(tab);
    returnToTheFirstPage();
  };

  const loadTopics = async (section, domains) => {
    if (domains?.length) {
      const res = await getTopicsForExtendedSearch({section, domains: domains.map(domain => domain.value) });
      const data = res?.data;
      setTopicOptions(Array.isArray(data) ? data : []);
    } else {
      setTopicOptions([]);
    }
  }

  const formattedTopicOptions = useMemo(() => {
    return topicOptions.map(topicOption => ({
      id: topicOption,
      name: topicOption,
      value: topicOption,
    }));
  }, [topicOptions]);

  const tableColumns = [
    { content: 'Name', id: 'Name', className: 'pl-0 py-[14px] font-normal' },
    { content: 'Difficulty', id: 'Difficulty', className: '!text-center lg:text-center py-[14px] font-normal' },
    { content: currentTab === TAB.PRACTICE ? 'Right' : 'Math Score', id: 'Math Score', className: 'text-center lg:text-right py-[14px] font-normal' },
    { content: currentTab === TAB.PRACTICE ? 'Wrong' : 'RW Score', id: 'RW Score', className: 'text-center lg:text-right py-[14px] font-normal' },
    { content: currentTab === TAB.PRACTICE ? 'Omitted' : 'Total Score', id: 'Total Score', className: 'text-center lg:text-right py-[14px] font-normal' },
    { content: 'Date Taken', id: 'Date Taken', className: 'text-center lg:text-right py-[14px] font-normal' },
    { content: 'Status', id: 'Status', className: 'text-center lg:text-right pr-0 py-[14px] font-normal' },
  ];

  const [availableAssessments, practiceAssessments] = useMemo(() => {
      const assessmentsWithStatus = attachAssignInfoToPublishedAssessments(publishedAssessments, assignedAssessments);

      return dividePracticeAndNotPracticeAssessments(assessmentsWithStatus);
    },
    [publishedAssessments, assignedAssessments]
  );

  const assessments = useMemo(
    () => {
      if (currentTab === TAB.AVAILABLE) {
        return availableAssessments;
      }

      const selectedDomainsValues = domains.map(item => item.value);
      const selectedTopicsValues = topics.map(item => item.value);

      return practiceAssessments.filter((assessment) =>
        (section ? assessment.tags?.find(tag => tag?.tagId === sectionTagId)?.values?.[0] === section : true) &&
        (domains.length ? selectedDomainsValues.includes(assessment.tags?.find(tag => tag?.tagId === domainTagId)?.values?.[0]) : true) &&
        (topics.length ? selectedTopicsValues.includes(assessment.tags?.find(tag => tag?.tagId === topicTagId)?.values?.[0]) : true) &&
        (difficulty ? assessment.tags?.find(tag => tag?.tagId === difficultyTagId)?.values?.[0] === difficulty : true)
      );
    },
    [currentTab, availableAssessments, practiceAssessments, section, domains, topics, difficulty]
  );

  const sortedAssessments = useMemo(() => {
    switch (studentsTableSortingType) {
      case SORTING.ALPHABETICAL: return sortAssessmentsByNameAlphabetically(assessments);
      case SORTING.NEWEST: return sortAssessmentsByNewest(assessments);
      default: return sortAssessmentsByStatus(assessments);
    }
  }, [assessments, studentsTableSortingType]);

  const filteredAssessments = useMemo(() => {
    const currentPage = currentTab === TAB.AVAILABLE ? availableAssessmentTablePage : practiceAssessmentTablePage;
    return filterItemsByPage(sortedAssessments, ASSESSMENTS_PER_PAGE, currentPage);
  }, [currentTab, availableAssessmentTablePage, practiceAssessmentTablePage, sortedAssessments]);

  const handleSelectDomain = (option) => {
    if (option?.value) {
      const newDomains = [ ...domains, option];
      setDomains(newDomains);
      returnToTheFirstPage();
      loadTopics(section, newDomains);
    }
  }

  const handleUnselectDomain = (optionId) => {
    if (optionId) {
      const newDomains = domains.filter(domain => domain.value !== optionId);
      setDomains(newDomains);
      returnToTheFirstPage();
      loadTopics(section, newDomains);
    }
  }

  const handleSelectTopic = (option) => {
    if (option?.value) {
      const newTopics = [ ...topics, option];
      returnToTheFirstPage();
      setTopics(newTopics);
    }
  }

  const handleUnselectTopic = (optionId) => {
    if (optionId) {
      const newTopics = topics.filter(topic => topic.value !== optionId);
      returnToTheFirstPage();
      setTopics(newTopics);
    }
  }

  const tableData = useMemo(() => {
    const handleSelectAssessment = (assessment) => () => {
      return Promise.all([
        setAssessmentId(assessment?.assessmentId),
        setAssessmentVersionId(assessment?.assessmentVersionId ?? assessment?.assessivVersionId ?? ''),
        setEducatorId(assessment?.educatorId ?? ''),
        resetResults(),
        setResultsReady(false)
      ])
        .then(() => navigate('/assessment/start'))
        .catch((error) => console.log(error));
    }

    return filteredAssessments?.map(assessment => {
      const isPracticeTab = currentTab === TAB.PRACTICE;
      return (
        {
          id: `${assessment.assessmentId}${assessment.assessmentVersionId}${assessment.educatorId}`,
          onClick: isPaidAssessment(assessment) ? undefined : handleSelectAssessment(assessment),
          cells: [
            {
              className: 'pl-0 py-[14px]',
              id: assessment.assessmentId + 'Name',
              content: (
                <div className="flex items-center gap-3">
                  {isPaidAssessment(assessment) && (
                    <Tooltip wrapperClassName="-mr-1.5" tooltipPopupClassName="!w-[156px]" text="Paid Assessiv. Payment required to access.">
                      <LockClosedIcon className="h-4" />
                    </Tooltip>
                  )}
                  {assessment.name}
                  <div className="ml-auto flex items-center gap-3">
                    {isInternalTagVisible(assessment) && (
                      <div className="flex items-center justify-center gap-2 w-max bg-whisperWhite rounded-lg px-2 py-1 text-xs">
                        <div className="w-1 h-1 rounded-full flex-shrink-0 bg-[#6d28d9]"/>
                        Internal
                      </div>
                    )}
                    {(assessment?.status === ASSESSMENT_STATUS.FRESH || !assessment?.status) && (
                      <div>
                        <StatusLabel className="relative top-px" status={assessment?.status}/>
                      </div>
                    )}
                  </div>
                </div>
              )
            },
            {
              id: assessment.assessmentId + 'Date',
              className: 'text-center',
              content: difficultyNameById[assessment?.tags?.find?.(tag => tag?.tagId === difficultyTagId)?.values?.[0] ?? ''] ?? '-'
            },
            {
              id: assessment.assessmentId + 'Math Score',
              content: assessment?.lastAtempt ? (isPracticeTab ? assessment?.lastAtempt?.right : assessment?.lastAtempt?.mathScore) : '-'
            },
            {
              id: assessment.assessmentId + 'RW Score',
              content: assessment?.lastAtempt ? (isPracticeTab ? assessment?.lastAtempt?.wrong : assessment?.lastAtempt?.rwScore) : '-'
            },
            {
              id: assessment.assessmentId + 'Total Score',
              content: assessment?.lastAtempt ? (isPracticeTab ? assessment?.lastAtempt?.omitted : assessment?.lastAtempt?.totalScore) : '-'
            },
            {
              id: assessment.assessmentId + 'Date Taken',
              content: assessment?.lastAtempt?.dateTaken ? formatToUSDate(assessment?.lastAtempt?.dateTaken) : '-'
            },
            {
              className: 'pr-0 font-normal',
              id: assessment.assessmentId + 'Status Score',
              content: (
                (assessment?.status === ASSESSMENT_STATUS.FRESH || !assessment?.status) ? (
                  <span className="text-xs text-gray-500">Not Started</span>
                ) : (
                  <StatusLabel status={assessment?.status} />
                )
              )
            },
          ]
        }
      );
    }) ?? [];
  }, [filteredAssessments, currentTab]);

  useEffect(() => {
    fetchPublishedAssessmentListByStudent()
      .then((data) => setPublishedAssessments(data?.data ?? []));
    fetchUserAssessmentList()
      .then(({ data }) => setAssessments(data));
    fetchCompletedAssessmentList()
      .then((data) => setCompletedAssessments(data?.data ?? []))
  }, []);

  useEffect(() => {
    if (assessmentTableTab !== currentTab) {
      setCurrentTab(assessmentTableTab);
    }
  }, [assessmentTableTab, currentTab]);

  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={backdropVariants}
        transition={{ duration: 0.3 }}
        id="staticModal"
        data-modal-backdrop="static"
        tabIndex="-1"
        aria-hidden="true"
        className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 bg-black bg-opacity-20 "
      >
        <motion.div
          variants={modalVariants}
          transition={{ duration: 0.5, stiffness: 150 }}
          className="relative w-full max-w-[1025px] max-h-full"
        >
          <div className="flex flex-col relative bg-white rounded shadow">
            <button
              type="button"
              className="text-gray-400 mt-3 mr-3 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm w-5 h-5 ml-auto inline-flex justify-center items-center"
              data-modal-hide="staticModal"
              onClick={closeModal}
            >
              <CloseCrossIcon className="w-3 h-3"/>
              <span className="sr-only">Close modal</span>
            </button>

            <div className={`flex flex-col p-8 md:p-6 pt-0 md:pt-0 text-gray-500 bg-white flex-1 rounded-lg shadow-sm ${INTROJS_SELECTOR.ASSESSMENTS}`}>
              <div className="flex items-center gap-2 mb-4 mt-1">
                <SingleTypeSelect
                  selectedOptionText={currentTab}
                  onSelectOption={(option) => handleOnClickTab(option?.value)}
                  options={tabs}
                  placeholder="Assessiv Type"
                  className="flex-1 w-full"
                  textClassName="!pr-0"
                />
                <SingleTypeSelect
                  selectedOptionText={sectionOptions.find(item => item.value === section)?.name ?? section}
                  onSelectOption={(option) => {
                    setSection(option?.id);
                    returnToTheFirstPage();
                  }}
                  options={sectionOptions.map(((option) => option.id === SECTION.READING_AND_WRITING ? {
                    ...option,
                    id: 'Verbal',
                    value: 'Verbal',
                  } : option))}
                  placeholder="Section"
                  className={`flex-1 w-full ${currentTab === TAB.PRACTICE ? '' : 'opacity-0 pointer-events-none'}`}
                  textClassName="!pr-0"
                />
                <MultipleCheckedInput
                  noOptionsText={section?.length ? undefined : 'Select section name'}
                  options={section?.length ? domainOptions : []}
                  selectedOptions={domains}
                  defaultDisplayName="Domains"
                  className={`flex-1 ${currentTab === TAB.PRACTICE ? '' : 'opacity-0 pointer-events-none'}`}
                  valuesContainerClassName="!max-h-[24px] !pr-1"
                  onSelectOption={handleSelectDomain}
                  onUnselectOption={handleUnselectDomain}
                />
                <MultipleCheckedInput
                  noOptionsText={(!!section?.length && !!domains.length) ? undefined : 'Select section and domains'}
                  options={(!!section?.length && !!domains.length) ? formattedTopicOptions : []}
                  selectedOptions={topics}
                  defaultDisplayName="Topic (optional)"
                  className={`flex-1 ${currentTab === TAB.PRACTICE ? '' : 'opacity-0 pointer-events-none'}`}
                  valuesContainerClassName="!max-h-[24px] !pr-1"
                  onSelectOption={handleSelectTopic}
                  onUnselectOption={handleUnselectTopic}
                />
                <SingleTypeSelect
                  selectedOptionText={difficultyOptions.find(item => item.value === difficulty)?.name ?? difficulty}
                  onSelectOption={(option) => {
                    setDifficulty(option?.value);
                    returnToTheFirstPage();
                  }}
                  options={difficultyOptions}
                  placeholder="Difficulty"
                  className={`!w-[120px] ${currentTab === TAB.PRACTICE ? '' : 'opacity-0 pointer-events-none'}`}
                  textClassName="!pr-0"
                />
              </div>

              <div className="flex flex-col flex-1 overflow-y-auto scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-lg min-h-[468px] max-h-[468px]">
                {assessments.length ? (
                  currentTab === TAB.COMPLETED ? (
                    <StudentCompletedAssessments
                      assessmentPerPage={ASSESSMENTS_PER_PAGE}
                      className="flex flex-col justify-between flex-1"
                    />
                  ) : (
                    <>
                      <Table
                        columns={tableColumns}
                        rows={tableData}
                        containerClassName="mb-2"
                        columnRowClassName="text-xs"
                      />

                      <div className="flex items-center justify-between mt-auto">
                        <div className="opacity-0 pointer-events-none"><SortedComboBox/></div>
                        <Pagination
                          currentPage={currentTab === TAB.AVAILABLE ? availableAssessmentTablePage : practiceAssessmentTablePage}
                          onPageChange={currentTab === TAB.AVAILABLE ? setAvailableAssessmentTablePage : setPracticeAssessmentTablePage}
                          totalPages={Math.ceil((assessments?.length ?? 0) / ASSESSMENTS_PER_PAGE)}
                        />
                        <SortedComboBox/>
                      </div>
                    </>
                  )
                ) : (
                  <div className="text-xl text-slate-500 text-center p-4 md:p-6 pt-8 md:pt-12">
                    No assessments
                  </div>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};