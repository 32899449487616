import { useMemo } from "react";
import { AssessmentOverviewBreadcrumbs } from "./components/AssessmentOverviewBreadcrumbs";
import { ViewCustomisation } from "./components/ViewCustomisation";
import { AssessmentInfo } from "./components/assessmentInfo";
import { HistoryOfResults } from "./components/HistoryOfResults";
import { Header } from "../../components/Header";
import { assessmentStore } from "../../store";

export default function AssessmentStart() {
  const { assessments, publishedAssessments, assessmentId, assessmentVersionId } = assessmentStore();

  const assessment = useMemo(() => {
    const assignedAssessment = assessments?.find((assessiv) =>
      assessiv.assessmentId === assessmentId &&
      assessiv.assessmentVersionId === assessmentVersionId
    );

    if (assignedAssessment) {
      return assignedAssessment;
    }

    return publishedAssessments?.find((assessiv) =>
      assessiv.assessmentId === assessmentId &&
      assessiv.assessivVersionId === assessmentVersionId
    );
  }, [assessments, publishedAssessments, assessmentId, assessmentVersionId]);

  return (
    <div className="flex flex-col bg-lightGrey min-h-screen">
      <Header />

      <div className="w-[1200px] max-w-full pt-8 pb-12 px-4 mx-auto">
        <div>
          <AssessmentOverviewBreadcrumbs assessmentName={assessment?.name} />

          <h1 className="text-[24px] leading-[32px] font-medium inline-block mt-4 mb-8">
            {assessment?.name}
          </h1>

          <div className="flex flex-col lg:flex-row gap-6">
            <div className="lg:w-2/3 flex flex-col items-stretch gap-2">
              <ViewCustomisation />
              {/*<InterfaceGuide />*/}
            </div>

            <div className="lg:w-1/3 flex flex-col items-stretch gap-2">
              <AssessmentInfo />
              <HistoryOfResults />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}