import { useRef, useState } from "react";
import { ReactComponent as Arrow } from "../../../assets/rt-arrow.svg";
import { useClickOutside } from "../../../helpers/hooks/useClickOutside.ts";

export const DropDown = ({
  disable,
  containerClassName,
  options,
  title,
  selectedOption,
  onSelectOption
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(prev => !prev);
  };

  const closeDropdown = () => setIsOpen(false);

  const handleSelectSubject = (option) => (event) => {
    event.stopPropagation();
    onSelectOption(option);
    closeDropdown();
  }

  useClickOutside(
    dropdownRef,
    closeDropdown
  );

  return (
    <div className={`flex flex-col items-stretch gap-2 ${disable ? 'greyscale pointer-events-none' : ''} ${containerClassName}`}>
      {!!title.length && (
        <h3 className={`text-sm ${disable ? 'text-slate-400' : 'text-duskyHarbor'}`}>
          {title}
        </h3>
      )}

      <div
        className={`flex flex-col relative border rounded text-sm ${disable ? 'bg-slate-200' : 'bg-white'} ${isOpen ? 'border-blue-400' : 'border-whisperBlue'}`}
        ref={dropdownRef}
      >
        <div
          className={`flex justify-between gap-1 items-center cursor-pointer h-10 py-2.5 px-4 ${
            disable ? 'text-slate-400' : selectedOption?.length ? 'text-black' : 'text-slate-600'}`
          }
          onClick={toggleDropdown}
        >
          <span>{selectedOption ?? 'Select option'}</span>
          <span className="rotate-90">
            <Arrow className="w-4 h-4" />
          </span>
        </div>

        {isOpen && (
          <div className="flex flex-col items-stretch px-3 py-1 absolute top-12 left-0 z-10 border border-blue-400 rounded bg-white w-full max-h-[200px] overflow-auto text-black scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md">
            {options?.length ? (
              options.map((option, index) => (
                <div
                  key={option}
                  className={`cursor-pointer px-0.5 py-2 border-t ${index === 0 ? 'border-transparent' : 'border-blue-400'}`}
                  onClick={handleSelectSubject(option)}
                >
                  {option}
                </div>
              ))
            ) : (
              <div className="w-full text-center text-slate-400 sm:text-sm px-0.5 py-1 border-t border-transparent">No options</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}