import {
  ASSESSMENT_STATUS,
  SERVICE_PLAN_TAG_ID,
  SERVICE_PLAN_TAG_PAID_VALUE,
  STATUS_TAG_ID,
  STATUS_TAG_PRACTICE_VALUE, TIME_TAG_ID
} from "./constants";

const generateTagValidationFunction = (validationTagId, validationTagValue) =>
  (assessment) => {
    const tag = assessment?.tags?.find?.(tag => tag?.tagId === validationTagId);
    const tagValue = tag?.values?.[0];
    return tagValue === validationTagValue;
  }

const isPaidAssessment = generateTagValidationFunction(SERVICE_PLAN_TAG_ID, SERVICE_PLAN_TAG_PAID_VALUE);

const isPracticeAssessment = generateTagValidationFunction(STATUS_TAG_ID, STATUS_TAG_PRACTICE_VALUE);

const dividePracticeAndNotPracticeAssessments  = (assessments) => {
  const notPracticeAssessments = [];
  const practiceAssessments = [];

  assessments?.forEach(assessment => {
    const resultArray = isPracticeAssessment(assessment) ? practiceAssessments : notPracticeAssessments;
    resultArray.push(assessment);
  })

  return [
    notPracticeAssessments,
    practiceAssessments,
  ];
}

const sortAssessmentsByNameAlphabetically = (assessments) => {
  return assessments.toSorted((firstAssessment, secondAssessment) => {
    const firstName = firstAssessment?.name?.toLowerCase() ?? '';
    const secondName = secondAssessment?.name?.toLowerCase() ?? '';

    if (firstName < secondName) return -1;
    if (firstName > secondName) return 1;
    return 0;
  });
}

const sortAssessmentsByNewest = (assessments) => {
  return assessments.toSorted((firstAssessment, secondAssessment) => {
    let firstDate = new Date(firstAssessment?.publishedDate);
    let secondDate = new Date(secondAssessment?.publishedDate);

    if (firstDate > secondDate) return -1;
    if (firstDate < secondDate) return 1;
    return 0;
  });
}

const sortAssessmentsByStatus = (assessments) => {
  return assessments?.toSorted((firstAssessment, secondAssessments) => {
    const statusOrder = {
      [ASSESSMENT_STATUS.IN_PROGRESS]: 1,
      [ASSESSMENT_STATUS.FRESH]: 2,
      [ASSESSMENT_STATUS.COMPLETED]: 3
    };

    const firstStatus = statusOrder[firstAssessment.status ?? ASSESSMENT_STATUS.FRESH] ?? 2;
    const secondStatus = statusOrder[secondAssessments.status ?? ASSESSMENT_STATUS.FRESH] ?? 2;

    return firstStatus - secondStatus;
  });
}

const attachAssignInfoToPublishedAssessments = (publishedAssessments, assignedAssessments) => {
  return publishedAssessments?.map(assessment => {
    const assignments = assignedAssessments?.filter(assignment => assessment?.assessmentId?.toLowerCase() ===
      assignment?.assessmentId?.toLowerCase() && assessment?.assessivVersionId?.toLowerCase() ===
      assignment?.assessmentVersionId?.toLowerCase()
    );

    if (assignments?.length) {
      return assignments.map(assignment => ({
        ...assessment,
        lastAtempt: assignment?.lastAtempt,
        isSelfAssign: assignment?.isSelfAssign,
        educatorId: assignment?.educatorId,
        status: assignment?.status,
      }));
    }

    return assessment;
  })?.flat() ?? [];
}

const getAssessmentSectionTime = (assessmentSection) => {
  const timeTag = assessmentSection?.tags?.find?.((tag) => tag?.tagId === TIME_TAG_ID);
  const sectionTime = timeTag?.values?.[0];

  if (typeof sectionTime === 'string' && !isNaN(Number(sectionTime))) {
    return Number(sectionTime);
  }

  return 0;
}

export {
  isPaidAssessment,
  isPracticeAssessment,
  dividePracticeAndNotPracticeAssessments,
  sortAssessmentsByNameAlphabetically,
  sortAssessmentsByNewest,
  sortAssessmentsByStatus,
  attachAssignInfoToPublishedAssessments,
  getAssessmentSectionTime,
}