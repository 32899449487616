import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { AddToGroupButton } from "./AddToGroup";
import { groupsStore, loginStore } from "../../../../../../../store";
import { Abbreviator } from "../../../../../../../helpers/index.ts";
import { createDebounceFunction } from "../../../../../../../helpers/functions.ts";
import { formatDateToTimeAgo } from "../../../../../../../helpers/date.ts";
import {
  sendEducatorNotificationAboutRoleChange
} from "../../../../../../../actions/emails";
import {
  getEducatorsList,
  changeRoleOfInvitedEducator,
  deleteInvitedEducator,
} from "../../../../../../../actions/user";
import Pagination from "../../../../../../../components/Pagination";
import { RemoveFromInvitedListModal } from "../../../../../../../components/RemoveFromInvitedListModal";
import { getRoleDisplayName, isAdminEducator, isOwnerEducator } from "../../../../../../../helpers/authentication.ts";
import { useClickOutside } from "../../../../../../../helpers/hooks/useClickOutside.ts";
import { ReactComponent as Arrow } from "../../../../../../../assets/rt-arrow.svg";
import { getRoles } from "../../../../../../../actions/registration";

const EDUCATORS_PER_PAGE = 10;

const RoleSelect = ({
  disable,
  selectedOption,
  onSelectOption,
  placeholder,
  getDisplayName = (value) => value,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const roles = ['Educator', 'EducatorAdmin'];

  const toggleDropdown = () => {
    setIsOpen(prev => !prev);
  };

  const closeDropdown = () => setIsOpen(false);

  const handleSelectSubject = (option) => (event) => {
    event.stopPropagation();
    onSelectOption(option);
    closeDropdown();
  }

  useClickOutside(
    dropdownRef,
    closeDropdown
  );

  return (
    <div
      className={`flex flex-col rounded-md text-xs bg-transparent my-2 mx-auto w-[130px] relative left-3`}
      ref={dropdownRef}
    >
      <div
        className={`flex justify-between gap-1 items-center rounded-md border cursor-pointer p-1.5 transition ${
          disable ? 'text-slate-400' : selectedOption?.length ? 'text-black' : 'text-slate-600'} ${
          isOpen ? 'border-blue-400' : 'border-transparent group-hover/educatorRow:border-gray-300'
        }`}
        onClick={toggleDropdown}
      >
        <span className="truncate">{selectedOption?.length ? getDisplayName(selectedOption) : (placeholder ?? 'Select option')}</span>
        <span className={`rotate-90 scale-90 transition ${isOpen ? '' : 'opacity-0 group-hover/educatorRow:opacity-100'}`}>
          <Arrow className="w-4 h-4"/>
        </span>
      </div>

      {isOpen && (
        <div
          className="flex flex-col items-stretch shadow absolute top-8 left-0 z-10 border border-whisperBlue text-left rounded-md bg-white w-full max-h-[200px] overflow-auto text-black scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md">
          {roles.map((role, index) => (
            <div
              key={role}
              className={`cursor-pointer p-1.5 border-t ${selectedOption === role ? 'bg-skyBlue text-white' : ''} ${index === 0 ? 'border-transparent' : 'border-whisperBlue'}`}
              onClick={handleSelectSubject(role)}
            >
              {getDisplayName(role)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const AllEducatorsTableRow = ({ educator, roles, refetchEducators }) => {
  const { fullName, email } = loginStore();
  const [educatorRole, setEducatorRole] = useState(educator?.role);

  const handleChangeRole = (role) => {
    if (educatorRole === role) return;
    const previousRole = educatorRole;

    setEducatorRole(() => role);
    const roleId = roles.find(item => item?.role === role)?.roleId;
    const educatorEmail = educator?.email;

    if (!!roleId && !!educatorEmail) {
      changeRoleOfInvitedEducator(educatorEmail, roleId)
        .then(() => {
          refetchEducators();
          sendEducatorNotificationAboutRoleChange({
            name: fullName,
            email: email,
            previousRole: getRoleDisplayName(previousRole),
            newRole: getRoleDisplayName(role),
            changedUserName: educator?.name,
          })
            .catch(console.log);
        })
        .catch((error) => {
          toast.error('Error when changing role.');
          setEducatorRole(() => previousRole);
          console.log(error);
        });
    }
  }

  const handleRemoveInvitedEducator = () => {
    deleteInvitedEducator(educator.userId)
      .then(refetchEducators)
      .catch(console.log);
  }

  useEffect(() => {
    setEducatorRole(educator?.role);
  }, [educator]);

  return (
    <tr className="transition-all duration-300 border-t border-alabaster group/educatorRow group hover:bg-lightGrey text-center text-sm">
      <td
        className="col-span-6 flex text-xs text-duskyHarbor items-center gap-4 text-left py-[14px] px-2 pl-6 sticky left-0 group-hover:bg-lightGrey transition-all duration-300 bg-white">
        <div className="flex items-center gap-4 font-medium">
          <div
            className="flex h-6 w-6 text-white font-medium text-[10px] leading-[16px] items-center justify-center rounded-full bg-blue-500">
            {Abbreviator(educator?.name?.toUpperCase() ?? '')}
          </div>
          {educator?.name}
        </div>
      </td>

      <td className="col-span-4 text-center px-2 text-xs">
        {isOwnerEducator() ? (
          <RoleSelect
            selectedOption={educatorRole}
            onSelectOption={handleChangeRole}
            getDisplayName={getRoleDisplayName}
            placeholder="Select role"
          />
        ) : getRoleDisplayName(educator?.role ?? '-')}
      </td>

      <td className="col-span-4 text-center px-2 text-xs">
        {formatDateToTimeAgo(educator?.lastLogin) ?? '-'}
      </td>

      <td className="col-span-2 px-2 text-right font-medium">
        <div className={`h-full w-full flex items-center justify-center ${isAdminEducator() ? '' : 'opacity-0 pointer-events-none'}`}>
          <AddToGroupButton userId={educator?.userId}/>
          <RemoveFromInvitedListModal handleRemoveUser={handleRemoveInvitedEducator} />
        </div>
      </td>
    </tr>
  );
}

const loadEducators = (searchString, page) => {
  const { setEducators } = groupsStore.getState();
  getEducatorsList(
    EDUCATORS_PER_PAGE,
    (page - 1) * EDUCATORS_PER_PAGE,
    searchString
  )
    .then((data) => {
      setEducators(data?.data);
    });
}

const debounceSearchEducators = createDebounceFunction(loadEducators, 600);

export const AllEducatorsTable = ({ searchString }) => {
  const { educators, setEducators } = groupsStore();
  const [currentPage, setCurrentPage] = useState(1);
  const [roles, setRoles] = useState(1);

  const handleOnChangePage = (newPage) => {
    setCurrentPage(newPage);
    loadEducators(searchString, newPage);
  }

  const fetchRoles = async () => {
    const fetchedRoles = await getRoles();
    setRoles(fetchedRoles);
  }

  const refetchEducators = () => {
    getEducatorsList(
      EDUCATORS_PER_PAGE,
      (currentPage * EDUCATORS_PER_PAGE) - EDUCATORS_PER_PAGE
    )
      .then((data) => {
        setEducators(data?.data ?? []);
      })
      .catch(console.log);
  }

  useEffect(() => {
    setCurrentPage(1);
    debounceSearchEducators(searchString, 1);
    if (isOwnerEducator()) {
      fetchRoles();
    }
  }, [searchString]);

  if (!educators?.userList?.length) {
    return (
      <div className="text-xl text-slate-500 text-center p-4 md:p-6 w-full">
        No educators
      </div>
    );
  }

  return (
    <>
      <div
        className="overflow-auto relative scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md w-full mb-6">
        <table className="min-w-full">
          <thead>
          <tr className="text-center text-fadedDenim pb-2 text-xs">
            <th className="col-span-6 text-left py-[14px] px-2 pl-6 sticky left-0 bg-white font-normal">
              Name
            </th>
            <th className="col-span-4 px-2 font-normal text-center">
              Role
            </th>
            <th className="col-span-4 px-2 font-normal text-center">
              Last login
            </th>
            <th className="col-span-2 px-2 font-normal text-center py-[14px]"></th>
          </tr>
          </thead>

          <tbody className="text-sm text-right text-black">
          {educators?.userList?.map((student) => (
            <AllEducatorsTableRow
              key={student?.accountId}
              educator={student}
              roles={roles}
              refetchEducators={refetchEducators}
            />
          ))}
          </tbody>
        </table>
      </div>

      <Pagination
        currentPage={currentPage}
        onPageChange={handleOnChangePage}
        totalPages={Math.ceil((educators?.totalCount ?? 0) / EDUCATORS_PER_PAGE)}
      />
    </>
  );
}